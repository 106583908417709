import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';
import ArrowUpRightSvg from '../../../../assets/icons/arrow-up-right.svg';
import './top-projects.styles.scss';
import Skeleton from '../../../Skeleton';
import Target from './target';
import searchFilter from '../../../../searchFilter';
import SuspenseImageStaticSize from '../../../SuspenseImageStaticSize';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from '../../../ErrorFallback';
import Browser from '../../../Browser';
import {FetchKeyStateContext} from '../../../FetchKey';
import useQuery from '../../../../useQuery';
import getTagValue, {getPosterUrl} from '../../../../utilities/get-tag-value';
import i18n from '../../../../i18n';
import getServiceUrl from '../../../../utilities/get-service-url';
import {format} from 'date-fns';
import {DEFAULT_SERVICE_DESCRIPTION_SEPARATOR} from '../../../../constants';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';

const TopProject = () => {
    const fetchKeyState = React.useContext(FetchKeyStateContext);
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    const {viewer} = useLazyLoadQuery(
        graphql`
            query topProjectsServicesByViewerQuery($first: Int!, $after: String) {
                viewer {
                    targetServices(first: $first, after: $after) @connection(key: "topProjects_targetServices") {
                        edges {
                            node {
                                id
                                slug
                                name
                                nameUk
                                details
                                detailsUk
                                location
                                locationUk
                                start
                                end
                                strategy
                                every
                                warp
                                target
                                description
                            }
                        }
                    }
                }
            }
        `,
        {first: 1},
        {fetchKey: fetchKeyState}
    );
    const service = viewer.targetServices.edges[0] && viewer.targetServices.edges[0].node;
    const cover = React.useMemo(() => {
            const posterUrl = getPosterUrl(service.description);
            if (posterUrl) {
                return posterUrl;
            } else if (service && i18n(service, 'details', locale)) {
                const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
                const found = i18n(service, 'details', locale).match(regex);
                return found && found[0];
            }
        }, [service, locale]);
    const serviceUrl = getServiceUrl({description: service.description, id: service.id, slug: service.slug, warp: service.warp, location});
    const target = service.target ? format(new Date(service.target), 'dd.MM.yyyy') : null;
    const tags = service.description ? service.description.split(DEFAULT_SERVICE_DESCRIPTION_SEPARATOR) : [];
    const targetEndVal = getTagValue(tags, 'targetEnd');
    const targetEnd = targetEndVal ? format(new Date(targetEndVal), 'dd.MM.yyyy') : null;
    return (
        <div 
            className='top-projects-block display-flex'
            data-insights-object-id={service.id}
        >
            <div className='top-projects-item'>
                <Link to={serviceUrl} className='display-block width-100percent height-100percent position-relative'>
                    {cover &&
                        <Browser>
                            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                                <SuspenseImageStaticSize
                                    className='display-block width-100percent height-100percent object-fit-cover border-radius-0dot75rem'
                                    src={cover}
                                />
                            </ErrorBoundary>
                        </Browser>
                    }
                    <div 
                        style={{
                            height: 189, 
                            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)'
                        }}
                        className='position-absolute bottom-0 width-100percent border-bottom-left-radius-0dot75rem border-bottom-right-radius-0dot75rem'
                    />
                    <div 
                        className='position-absolute bottom-0 width-100percent height-100percent display-flex flex-direction-column justify-content-flex-end border-radius-0dot75rem'
                    >
                        <div 
                            style={{borderTop: '1px solid rgba(255, 255, 255, 0.5)'}}
                            className='border-bottom-left-radius-0dot75rem border-bottom-right-radius-0dot75rem padding-top-1dot5rem padding-left-1dot25rem padding-right-1dot25rem padding-bottom-2rem backdrop-filter-blur-0dot75rem'
                        >
                            <div>
                                <div className='display-flex justify-content-space-between'>
                                    <div className='text-2xl semibold color-white'>
                                        {i18n(service, 'name', locale)}
                                    </div>
                                    <div className='width-1dot5rem height-1dot5rem display-flex justify-content-center align-items-center'>
                                        <ArrowUpRightSvg className='display-block width-0dot75rem height-0dot75rem color-gray-25'/>
                                    </div>
                                </div>
                                <div className='margin-top-0dot5rem text-md color-white'>
                                    {`${target}${(targetEnd && target !== targetEnd) ? ` — ${targetEnd}` : ''}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

const TopProjects = () => {
    const location = useLocation();
    return (
        <>
            <div className='top-projects-container display-flex'>
                <React.Suspense fallback={
                    <div className='top-projects-block display-flex'>
                        <div className='top-projects-item top-projects-item--small'></div>
                        <div className='top-projects-item'>
                            <div className='height-100percent display-flex flex-direction-column justify-content-flex-end'>
                                <div className='padding-top-1dot5rem padding-left-1dot25rem padding-right-1dot25rem padding-bottom-2rem'>
                                    <Skeleton/>
                                </div>
                            </div>
                        </div>
                    </div>
                }>
                    <Target/>
                </React.Suspense>
                {/* <div className='top-projects-block display-flex'>
                    <div className='top-projects-item top-projects-item--small'></div> */}
                    {/* <a target='_blank' href='https://e-aid.diia.gov.ua' rel='noopener noreferrer' className='top-projects-item top-projects-item--e-aid display-flex position-relative'>
                        <div className='top-projects-item__card align-self-flex-start display-flex'>
                            <p className='top-projects-item__card-title'><FormattedMessage defaultMessage='We accept eAid cards' /></p>
                            <img className='top-projects-item__card-img' style={{height: 110}} src={ImgPng} alt=''/>
                        </div>
                        <div className='top-projects-item__description'>
                            <p className='top-projects-item__title'><FormattedMessage defaultMessage='eAid' /></p>
                            <p className='top-projects-item__subtitle'><FormattedMessage defaultMessage='Details here' /></p>
                            <div className='top-projects-item__link'>
                                <ArrowUpRightSvg className='top-projects-item__link-img' />
                            </div>
                        </div>
                    </a> */}
                    {/* <Link className='top-projects-item top-projects-item--arsenal display-flex' to={`/services/arsenal${searchFilter(location.search)}`}>
                        <div className='top-projects-item__description'>
                            <p className='top-projects-item__title'>ARSENAL XXII</p>
                            <p className='top-projects-item__subtitle'>Cultural center</p>
                            <div className='top-projects-item__link'>
                                <ArrowUpRightSvg className='top-projects-item__link-img' />
                            </div>
                        </div>
                    </Link> */}
                {/* </div> */}
                <React.Suspense fallback={
                    <div className='top-projects-block display-flex'>
                        <div className='top-projects-item top-projects-item--small'></div>
                        <div className='top-projects-item'>
                            <div className='height-100percent display-flex flex-direction-column justify-content-flex-end'>
                                <div className='padding-top-1dot5rem padding-left-1dot25rem padding-right-1dot25rem padding-bottom-2rem'>
                                    <Skeleton/>
                                </div>
                            </div>
                        </div>
                    </div>
                }>
                    <TopProject/>
                </React.Suspense>
                {/* <div className='top-projects-block display-flex'>
                    <Link className='top-projects-item top-projects-item--master-wash display-flex' to={`/master-wash/services/5f140f544c3f4efa759438ce${searchFilter(location.search)}`}>
                        <div className='top-projects-item__description'>
                            <p className='top-projects-item__title'>MasterWash</p>
                            <p className='top-projects-item__subtitle'><FormattedMessage defaultMessage='Self-service car wash' /></p>
                            <div className='top-projects-item__link'>
                                <ArrowUpRightSvg className='top-projects-item__link-img' />
                            </div>
                        </div>
                    </Link>
                    <div className='top-projects-item top-projects-item--small'></div>
                </div> */}
            </div>
        </>
    )
};

export default React.memo(TopProjects);