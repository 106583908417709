import React from 'react';
import { FormattedMessage } from 'react-intl';
import './Shell.scss';
import CalendarSvg from '../../../assets/icons/calendar.svg';
import MarkerPin01Svg from '../../../assets/icons0/marker-pin-01.svg';
import Masonry from './Masonsy';
import {FetchKeyStateContext} from '../../FetchKey';
import {graphql, useLazyLoadQuery, usePaginationFragment, useFragment} from 'react-relay/hooks';
import {ErrorBoundary} from 'react-error-boundary';
import i18n from '../../../i18n';
import SuspenseImageStaticSize from '../../SuspenseImageStaticSize';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../../searchFilter';
import SuspenseList from '../../SuspenseList';
import useQuery from '../../../useQuery';
import Button, {RouterLinkButton} from '../../button/button';
import ArrowUpRightSvg from '../../../assets/icons/arrow-up-right.svg';
import Skeleton from '../../Skeleton';
import Browser from '../../Browser';
import ErrorFallback from '../../ErrorFallback';
import getServiceUrl from '../../../utilities/get-service-url';
import getTagValue, {getPosterUrl, getServiceTagValue} from '../../../utilities/get-tag-value';
import {format} from 'date-fns';
import {DEFAULT_SERVICE_DESCRIPTION_SEPARATOR} from '../../../constants';

const number = process.env.NODE_ENV === 'development' ? 2 : null;

const ServiceListEntry = React.memo(({serviceById}) => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    const fServiceById = useFragment(
        graphql`
            fragment serviceListEntry_service on Service {
                id
                slug
                name
                nameUk
                details
                detailsUk
                location
                locationUk
                start
                end
                strategy
                every
                warp
                target
                description
                pin
            }
        `,
        serviceById
    );
    const cover = React.useMemo(() => {
        const posterUrl = getPosterUrl(fServiceById.description);
        if (posterUrl) {
            return posterUrl;
        } else if (i18n(fServiceById, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(fServiceById, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [fServiceById, locale]);
    const serviceUrl = getServiceUrl({description: fServiceById.description, id: fServiceById.id, slug: fServiceById.slug, warp: fServiceById.warp, location});
    const locationName = i18n(fServiceById, 'location', locale) || decodeURI(getServiceTagValue(fServiceById.description, 'locationName', {defaultValue: ''}));
    const target = fServiceById.target ? format(new Date(fServiceById.target), 'dd.MM.yyyy') : null;
    const tags = fServiceById.description ? fServiceById.description.split(DEFAULT_SERVICE_DESCRIPTION_SEPARATOR) : [];
    const targetEndVal = getTagValue(tags, 'targetEnd');
    const targetEnd = targetEndVal ? format(new Date(targetEndVal), 'dd.MM.yyyy') : null;
    return (
        <div 
            className='background-color-white border-radius-0dot75rem padding-left-1dot5rem padding-top-1dot5rem padding-right-1dot5rem padding-bottom-1dot5rem shadow-large-light'
            style={!!fServiceById.pin ? {
                border: '4px solid var(--primary-600, #1570EF)',
                boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)',
                paddingTop: '20px',
                paddingBottom: '20px',
            } : {}}
        >
            <Link to={serviceUrl} className='display-block'>
                {cover &&
                    <Browser>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <SuspenseImageStaticSize
                                className='max-width-100percent border-radius-0dot5rem card-shadow-small'
                                src={cover}
                            />
                        </ErrorBoundary>
                    </Browser>
                }
                <div className='padding-top-0dot75rem'>
                    {!!fServiceById.pin &&
                    <div className='padding-top-0dot75rem'>
                        <div className='display-flex'>
                            <div className='padding-0dot25rem padding-right-0dot625rem background-color-success-50 border-radius-1rem'>
                                <div className='display-flex align-content-center'>
                                    <div className='padding-top-0dot125rem padding-right-0dot625rem padding-bottom-0dot125rem padding-left-0dot625rem background-color-success-600 border-radius-1rem'>
                                        <div className='text-sm media color-white'><FormattedMessage defaultMessage='Top event'/></div>
                                    </div>
                                    <div className='padding-left-0dot75rem padding-top-0dot125rem padding-bottom-0dot125rem'>
                                        <div className='text-sm medium color-success-700'><FormattedMessage defaultMessage='Ottry recommends'/></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div className='padding-top-0dot75rem text-xl semibold color-gray-900'>
                    {i18n(fServiceById, 'name', locale)}
                </div>
                {target &&
                    <div className='margin-top-0dot75rem display-flex align-items-center'>
                        <CalendarSvg className='width-1dot25rem height-1dot25rem color-gray-400'/>
                        <div className='text-md medium color-gray-500 margin-left-0dot5rem'>
                            {target}
                        </div>
                        {targetEnd && target !== targetEnd &&
                            <span className='text-md medium color-gray-500 padding-left-0dot25rem'>{` — ${targetEnd}`}</span>
                        }
                    </div>
                }
                {locationName &&
                    <div className='margin-top-0dot75rem display-flex'>
                        <MarkerPin01Svg className='flex-shrink-0 display-block width-1dot25rem height-1dot25rem color-gray-400 margin-top-0dot125rem'/>
                        <div className='text-md medium color-gray-500 margin-left-0dot5rem overflow-wrap-break-word'>
                            {locationName}
                        </div>
                    </div>
                }
            </Link>
            <div className='padding-top-2rem'>
                <RouterLinkButton to={serviceUrl} plain='true' color='primary' iconPosition='right'>
                    <FormattedMessage defaultMessage='Buy ticket' />
                    <ArrowUpRightSvg />
                </RouterLinkButton>
            </div>
        </div>
    );
});

const breakpointCols = {default: 3, 767: 1};
const ServiceListImpl = React.memo(({viewer, redirectOnAction}) => {
    const {data, loadNext, hasNext, isLoadingNext} = usePaginationFragment(
        graphql`
            fragment serviceListImpl_viewer on Viewer @refetchable(queryName: "ServiceListImplQuery") {
                targetServices(first: $first, after: $after) @connection(key: "ServiceList_targetServices") {
                    edges {
                        node {
                          id
                          ...serviceListEntry_service
                        }
                    }
                }
            }
            
        `,
        viewer
    );
    return (
        <>
        <SuspenseList revealOrder='forwards'>
            <Masonry
                breakpointCols={breakpointCols}
                className='masonry-grid'
                columnClassName='masonry-grid_column'
            >
                {data.targetServices.edges.map(e => e.node).map((service) =>
                    <div
                        key={service.id}
                        data-insights-object-id={service.id}
                    >
                        <ErrorBoundary>
                            <React.Suspense fallback={
                                <div className='background-color-white border-radius-0dot75rem padding-left-1dot5rem padding-top-1dot5rem padding-right-1dot5rem padding-bottom-1dot5rem shadow-large-light'>
                                    <Skeleton/>
                                </div>
                            }>
                                <ServiceListEntry {...{serviceById: service}} />
                            </React.Suspense>
                        </ErrorBoundary>
                    </div>
                )}
            </Masonry>
            <div/>
        </SuspenseList>
        {hasNext && !redirectOnAction &&
        <div className='margin-top-1dot5rem display-flex justify-content-center'>
            <Button {...{size: 'xl', color:'secondary-gray', disabled: isLoadingNext, clickHandler: () => !isLoadingNext && loadNext(number || 8)}}>
                <FormattedMessage defaultMessage='More events' />
                {isLoadingNext && '...'}
            </Button>
        </div>
        }
        {hasNext && redirectOnAction &&
            <div className='margin-top-1dot5rem display-flex justify-content-center'>
                <RouterLinkButton {...{size: 'xl', color: 'secondary-gray', to: redirectOnAction}}>
                    <FormattedMessage defaultMessage='More events' />
                </RouterLinkButton>
            </div>
        }
        </>
    );
});

export const ServiceList = React.memo(({redirectOnAction}) => {
    const fetchKeyState = React.useContext(FetchKeyStateContext);
    const {fromMore} = useQuery();
    const data = useLazyLoadQuery(
        graphql`
            query serviceListQuery($first: Int!, $after: String) {
                viewer {
                    ...serviceListImpl_viewer
                }
            }
        `,
        {first: fromMore ? (number || 8)*2 : number || 8},
        {fetchKey: fetchKeyState}
    );
    return (
        <ServiceListImpl {...{viewer: data.viewer, redirectOnAction}}/>
    );
});

export const ServiceListHomePage = React.memo(() => {
    const location = useLocation();
    return (
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={<Skeleton/>}>
                <ServiceList {...{redirectOnAction: `/events${searchFilter(location.search, {fromMore: true})}`}}/>
            </React.Suspense>
        </ErrorBoundary>
    )
});

export default React.memo(() => {
    return (
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={<Skeleton/>}>
                <ServiceList/>
            </React.Suspense>
        </ErrorBoundary>
    );
});
